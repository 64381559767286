<template>
  <div class="account-settings-info-view" style="width:80%; margin:0 auto;">
    <a-upload-dragger
      name="file"
      :action="actionUrl"
      :data="new_multipart_params"
      :before-upload="beforeUpload"
      @preview="handlePreview"
      @change="handleChange"
    >
      <p class="ant-upload-drag-icon">
        <a-icon type="inbox" />
      </p>
      <p class="ant-upload-text">单击或拖动文件到此区域</p>
    </a-upload-dragger>
  </div>
</template>

<script>
import { oss_token_list } from '@/api/oss_token'

// function getBase64 (file) {
//   return new Promise((resolve, reject) => {
//     const reader = new FileReader()
//     reader.readAsDataURL(file)
//     reader.onload = () => resolve(reader.result)
//     reader.onerror = error => reject(error)
//   })
// }

export default {
  name: 'Upload',
  data () {
    return {
      actionUrl: '',
      fileList: [],
      new_multipart_params: {
        'key': '',
        'policy': '',
        'OSSAccessKeyId': '',
        'success_action_status': '200',
        'signature': ''
      },
      new_exit_multipart_params: {
        'key': '',
        'policy': '',
        'OSSAccessKeyId': '',
        'success_action_status': '200',
        'signature': ''
      }
    }
  },
  created () {
    this.fileList = []
    oss_token_list().then(({ data }) => {
      this.new_multipart_params.OSSAccessKeyId = data.accessid
      this.new_multipart_params.policy = data.policy
      this.new_multipart_params.signature = data.signature
      // 出口
      this.new_exit_multipart_params.OSSAccessKeyId = data.accessid
      this.new_exit_multipart_params.policy = data.policy
      this.new_exit_multipart_params.signature = data.signature
      this.actionUrl = 'https://static.jnzbtz.com'
    })
  },
  methods: {
    beforeUpload (file) {
      const name = file.name
      const suffix = name.substring(name.lastIndexOf('.') + 1)
      this.new_multipart_params.key = 'admin_files_' + file.uid + '.' + suffix
      // const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png'
      // if (!isJpgOrPng) {
      //   this.$message.error('You can only upload JPG file!')
      // }
      const isLt500M = file.size / 1024 / 1024 < 500
      return isLt500M
    },
    handlePreview (file) {
      window.open(file.url, '_blank')
    },
    handleChange ({ fileList, file }) {
      this.fileList = fileList
      // console.log('我是图片', fileList)
      console.log('我是图片', file)
      if (file.status === 'removed') {
        this.$message.error('请重新上传')
      }
      if (file.status === 'done') {
        this.fileList.map((element, index, array) => {
          console.log(index + '-' + element + array)
          if (element.uid === file.uid) {
            this.fileList[index].url = this.actionUrl + '/' + this.new_multipart_params.key
          }
        })
        console.log('我是图片', this.fileList)
        // console.log(this.actionUrl + '/' + this.new_multipart_params.key)
      }
    }
  }
}
</script>

<style lang="less" scoped>

.avatar-upload-wrapper {
  height: 200px;
  width: 100%;
}

.ant-upload-preview {
  position: relative;
  margin: 0 auto;
  width: 100%;
  max-width: 180px;
  border-radius: 50%;
  box-shadow: 0 0 4px #ccc;

  .upload-icon {
    position: absolute;
    top: 0;
    right: 10px;
    font-size: 1.4rem;
    padding: 0.5rem;
    background: rgba(222, 221, 221, 0.7);
    border-radius: 50%;
    border: 1px solid rgba(0, 0, 0, 0.2);
  }

  .mask {
    opacity: 0;
    position: absolute;
    background: rgba(0, 0, 0, 0.4);
    cursor: pointer;
    transition: opacity 0.4s;

    &:hover {
      opacity: 1;
    }

    i {
      font-size: 2rem;
      position: absolute;
      top: 50%;
      left: 50%;
      margin-left: -1rem;
      margin-top: -1rem;
      color: #d6d6d6;
    }
  }

  img, .mask {
    width: 100%;
    max-width: 180px;
    height: 100%;
    border-radius: 50%;
    overflow: hidden;
  }
}
</style>
